import {useEffect, useRef, useState} from 'react';
import "../../styles/welcome.css"
import HamburgerButton from"./HamburgerButton";
import NavMenu from "./NavMenu";
import famaly from "../../img/welcome_block/new_famaly.svg"
import paper_plain from "../../img/welcome_block/paper_plane.svg"
import '../../js/scroll.js'


export const handleNavigate = (event) => {
  event.preventDefault();
  const blockID = event.currentTarget.getAttribute('href');
  const element = document.querySelector(blockID);

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
}

const Header = () => {

  const [isActive, setIsActive] = useState(false);
  const [logoIsVisible, setLogoIsVisible] = useState(true);
  const [hasAnimated, setHasAnimated] = useState(false);
  const logoRef = useRef(null);

  const toggleMenuActive = (value) => {
    setIsActive(value)
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (!entry.isIntersecting && !hasAnimated) {
        setLogoIsVisible(false);
        setHasAnimated(true);
      }
    });

    observer.observe(logoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [hasAnimated]);

  const toggleBurgerBut = () => {
    if(isActive) 
      setIsActive(false)
    else
      setIsActive(true)
  }

  return (
    <div id="main" className="welcome_block">
      <header className="header">
        <div ref={logoRef} className="logo">
          <a href="#footer" onClick={(e) => handleNavigate(e)}>
            <span>english with elina</span></a></div>
      <HamburgerButton 
        isActive={isActive}
        toggleBurgerButCallback={toggleBurgerBut}/> 
      <NavMenu 
        isOpen={isActive}
        toggleMenuActive={toggleMenuActive}
      />
      </header>

      <div className="main">

        <div className="monologue_block">
            <div className="rectangle">
              <div className="monologue_text">
                <span itemprop="name" className="name">English with Elina</span>
                <span className="school">школа английского языка</span>
                <span className="introduction">Присоединяйтесь к нам в нашем путешествии по изучению английского языка!</span>
                <button className="try_button">
                  <a href="#form"
                  onClick={(e) => handleNavigate(e)}
                  >ЗАПИСАТЬСЯ НА ПРОБНЫЙ УРОК</a></button>
              </div>
              <div className="triangle"></div>
            </div>
        </div>

        <div className='famaly_block'>
          <img src={famaly} className="family_img" alt=""></img>
          <img src={paper_plain} className={`paper_plain_img_phone ${!logoIsVisible ? "fly_away" : ""}`} alt=""></img>
        </div>
        <img src={paper_plain} className={`paper_plain_img ${!logoIsVisible ? "fly_away" : ""}`} alt=""></img>
      </div>

  </div>
  );
}

  

export default Header;