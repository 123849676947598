import {useState} from 'react';

import '../styles/certificates.css'
import certificate1 from '../img/certificates/certificate_1.svg'
import certificate2 from  '../img/certificates/certificate_2.svg'
import certificate3 from  '../img/certificates/certificate_3.svg'
import certificate4 from  '../img/certificates/certificate_4.svg'
import certificate5 from  '../img/certificates/certificate_5.svg'

import { Navigation, A11y } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SlideNextButton, { SlidePrevButton } from './buttons/SwiperButton';


const CertificatesData = [
  certificate1, certificate2, certificate3, certificate4, certificate5
];

const CertificatesBlock = () => {

   const [swiper, setSwiper] = useState(null);


   return (
    <div className='certificates_block'>
       <div className="certificates">
        <div className="certificates__title">
          наши сертификаты
        </div>
        <div className="certificates__container">
          <SlidePrevButton swiper={swiper}/>
          <Swiper
            modules={[Navigation, A11y]}
            breakpoints={{
              340: {
                slidesPerView: 1,
                spaceBetween: 55,
              },
              860: {
                slidesPerView: 1,
                spaceBetween: 55,
              },

              890: {
                slidesPerView: 2,
                spaceBetween: 55,
              },
              1221: {
                slidesPerView: 2,
                spaceBetween: 55,
              },
              1222: {
                slidesPerView: 3,
                spaceBetween: 55,
              }
            }}

            onSwiper={setSwiper}
            className="mySwiper"
          >
      
            {CertificatesData.map((data, index_data) => (
              <SwiperSlide key={index_data} className='mySwiperSlide'>
                <div className="certificate">
                  <img src={data} className="certificate_img" alt=""></img>
                </div>
              </SwiperSlide>
            ))}

        </Swiper>
        <SlideNextButton swiper={swiper}/>
        </div>
      </div>

    </div>
   );
}

export default CertificatesBlock;