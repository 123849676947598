import {useState} from 'react';
import "../styles/feedbacks.css"
import Mills from "../img/feedback_block/slides_back/mills.svg";
import Canada from "../img/feedback_block/slides_back/canada.svg";
import NewYork from "../img/feedback_block/slides_back/new_york.svg";
import London from "../img/feedback_block/slides_back/london.svg";
import Sydney from "../img/feedback_block/slides_back/sydney.svg";
import India from "../img/feedback_block/slides_back/india.svg";
import Philippines from "../img/feedback_block/slides_back/philippines.svg";
import Italy from "../img/feedback_block/slides_back/italy.svg";
import Hill from "../img/feedback_block/slides_back/hill.svg";
import SlideNextButton, { SlidePrevButton } from './buttons/SwiperButton';
import screensaver_0 from '../img/feedback_block/screensavers/screensaver_1.svg'
import screensaver_1 from '../img/feedback_block/screensavers/screensaver_2.svg'
import screensaver_3 from '../img/feedback_block/screensavers/screensaver_3.svg'


import { Navigation, A11y } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import VideoFeedback from './VideoFeedback';

export const FeedbackData = [
  {
    back_img: London,
    feedback: "https://www.youtube.com/embed/43UGXDoIf0g?si=fNygnT_Gvc7Lud6-",
    source: screensaver_0,
    author: ""
  },
  {
    back_img: India,
    feedback: "https://www.youtube.com/embed/MKON8MfO0j8?si=J_Ox2_AosVvnCDKs",
    source: screensaver_1,
    author: ""
  },
  {
    back_img: Hill,
    feedback: "https://www.youtube.com/embed/1IObXN5b8Q8?si=zyGwhZ_yzJvgICqp",
    source: screensaver_3,
    author: ""
  },
  {
    back_img: Mills,
    feedback: "С Элиной меня свела платформа Skyeng, мне было предложено выбрать учителя, и до сих пор я считаю, " +
    "что это был один из самых удачных выборов в моей жизни! За более чем два года обучения с ней у меня ни разу " +
    "не возникала мысть о том, что я не хочу приходит на урок, или что мне надоело учить английский. Элина всегда " +
    "могла изменить мой настрой в лучшую сторону. Даже если в начале урока у меня было не очёнь хорошее настроение, " +
    "то в конце я всегда уходила с улыбкой. Сначала мне было страшно, ведь до этого у меня практически не было опыта " +
    "общения полностью на английском языке, я очень стеснялась, но открытость и дружелюбие Элины быстро заставили меня " +
    "расслабиться. Она раскрылась для меня не только как учитель, но и как интересная личность, поэтому мне всегда хотелось " +
    "поделиться с ней чем-то новым и интересным или обсудить что-то, тем самым тренируя разговорную речь." +
    "Уроки были интересные, но они ограничивались возможностями онлайн платформы. Я заметила, что Элина не просто шла по заданной программе, " +
    "а всегда выбирала самое захватывающее и полезное, и у нее было много идей для проведения уроков. Мой уровень английского поднялся а так же " +
    "я смогла набраться смелости, собрать все свои знания в кучу и использовать их в разговоре с носителями, не теряясь от страха, " +
    "что сильно помогло мне для достижения моих нынешних целей. Элина стала для меня не просто прекрасным учителем, но еще и хорошим другом, " +
    "способным поддержать в трудную минуту.",
    author: "Дарья"
  },
  {
    back_img: Canada,
    feedback: "Хочу рассказать о своем опыте изучения английского языка." +
    "Помимо сухой теории в изучении языка, очень важен подход и преподаватель. " +
    "И мне повезло в обеих случаях." +
    "Я практически с нуля начала изучать язык с Элиной." +
    "Элина умеет правильно передать информацию, чтоб" +
    "На ее уроках много времени посвящали именно общению, учились выражать свои мысли и не боятся ошибаться." +
    "Благодаря ей я расширила свой словарь, научилась верно использовать времена. С теплотой вспоминаю наши уроки.",
    author: "Елена"
  },
  {
    back_img: NewYork,
    feedback: "Честно скажу, я начала любить и учить английский только благодаря Элине. " +
    "Её методика реально отличается от всех и кроме методики, лично для меня было важно " +
    "учиться в такой атмосфере, где будут поддерживать тебя." +
    "Элина умеет правильно передать информацию, чтоб максимально быстро освоить урок." +
    "Хочу ещё отметить что на наших курсах я начала разговаривать уверенно, что и было " +
    "для меня уже победой. Спасибо огромное за все знания которые я получила с Элиной.",
    author: "Диана"
  },
  {
    back_img: Sydney,
    feedback: "Элина - это любовь с первого взгляда! Этот репетитор расположил к " +
    "себе и сделал дружелюбную, уютную атмосферу на первом же занятии. Каждое занятие " +
    "проходит невероятно интересно, несмотря на то, что занимаюсь с Элиной я уже 3 года." +
    "Именно с ней у меня получилось разрушить языковой барьер, начать свободно разговаривать " +
    "на языке в других странах. Также Элина подготовила меня к международному экзамену " +
    "по английскому языку TOEFL за полгода на 83 балла. Безумно люблю Элину и каждое занятие с ней!",
    author: "Анна"
  },
  {
    back_img: Philippines,
    feedback: "Before meeting Elina I thought that learning English is boring and hard. English lessons at school consisted of grammar exercises from textbook and stupid texts which you should read for having good marks. After our first class with Elina I was really excited because we just had a simple talk (the same as I chat with my friends) but in English. Next lessons were full of small talks before classes, different types of tasks (so you won't get bored). We were watching films, videos, fragments from TV shows which I really liked. With Elina I've read my first book in English and more importantly that I've overcome the fear of speaking English. I'm so grateful for having English teacher as exercises from textbook and stupid texts which you should read for having good marks. After our first class with Elina I was really excited because we just had a simple talk (the same as I chat with my friends) but in English. Next lessons were full of small talks before classes, different types of tasks (so you won't get bored). We were watching films, videos, fragments from TV shows which I really liked. With Elina I've read my first book in English and more importantly that I've overcome the fear of speaking English. I'm so grateful for having English teacher as Elina. It's not so easy to find someone who would be so qualified, patient, friendly and able to explain clear tricky things as she is.",
    author: "Мария"
  },
  {
    back_img: Italy,
    feedback: "El jan, you are one of the enthusiastic teachers I have ever seen, you have good teaching skills and I really appreciate that you care about your students and force them learn (I have had many English teachers, but I can say that most of them don't care if we learn or not), the another thing is that you always remind us of the learned lessons and don't let us forget the phrases, grammarand so on, and the third thing is that we improve not only speech, but also lestining, and after each unit we write a test and look at its results. You are a very positive and responsible person and I will never get bored of our lessons, which are like interesting games.",
    author: "Эля"
  }
];


const FeedbackBlock = () => {

  const [swiper, setSwiper]             = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlay_0, setIsPlay_0]         = useState(false);
  const [isPlay_1, setIsPlay_1]         = useState(false);

  const togglePlay = (index) => {
    switch(index) {
      case  0:
        setIsPlay_0(true);
        break;
      case  1:
        setIsPlay_1(true);
        break;
      default:
        console.error(`Неизвестный индекс: ${index}`);
    }
  };

  return (

    <div id="feedbacks" className="feedback_block item_container">
      
      <div className="container__title">отзывы наших учеников</div>
        <Swiper className="container__swiper"
          modules={[Navigation, A11y]}
          slidesPerView={1}
          speed={500}
          onSwiper={setSwiper}
          onSlideChange={(swiper) =>  setCurrentIndex(swiper.activeIndex)}
        >
          {FeedbackData.map((data, index) => {
            return (
              <SwiperSlide 
                key={index} 
                className='mySwiperSlide'
                style={{ 
                  backgroundImage: `url(${data.back_img})` 
                }}
              >
                <SlidePrevButton swiper={swiper}/>
                <div className={`feedback ${data.author ? "like_text" : "like_video"}`}>
                  {
                    data.author ? 
                      <>
                        <p>{data.feedback}</p>
                        <span>{data.author}</span>
                      </>
                      :
                      <VideoFeedback data={data}/>
                  }                 

                </div>
                <SlideNextButton swiper={swiper}/>
              </SwiperSlide>
            );

          })}
        </Swiper>
    </div>

  );
}

export default FeedbackBlock;