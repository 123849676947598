import {useState} from 'react';
import "../styles/prices.css"
import "../styles/buttons.css"

import { Navigation, A11y } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { handleNavigate } from './header/Header'

import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SlideNextButton, { SlidePrevButton } from './buttons/SwiperButton';
import '../js/scroll.js'

const PriceData = [
  {
    title: "индивидуальные занятия для детей",
    info_text: [
      "Программа строится под ваши цели и особенности",
      "Минимум 4 занятия по интересующим вам тематикам",
      "Поддержка личного куратора",
      "Длительность занятия 1 аккадемический час (45 мин)"
    ],
    price: "6500 р."
  },
  {
    title: "групповые занятия для детей",
    info_text: [
      "Максимум 4 человека в группе",
      "Минимумм 8 занятий",
      "Длительность занятия 1 час",
      "Преодоление языкового и слухового барьера"
    ],
    price: "5000 р."
  },
  {
    title: "индивидуальные занятия для подростков",
    info_text: [
      "Программа строится под ваши цели и особенности",
      "Минимумм 4 занятия",
      "Поддержка личного куратора",
      "Длительность занятия 1 аккадемический час (45 мин)"
    ],
    price: "5000 р."
  },
  {
    title: "групповые занятия для подростков",
    info_text: [
      "Максимум 4 человека в группе",
      "Минимум 8 занятий",
      "Длительность занятия 1 час",
      "Преодоление языкового и слухового барьера"
    ],
    price: "5000 р."
  },
  {
    title: "индивидуальные занятия для взрослых",
    info_text: [
      "Программа строится под ваши цели и особенности",
      "Минимумм 4 занятия по интересующим вам тематикам",
      "Поддержка личного куратора",
      "Длительность занятия 1 аккадемический час (45 мин)"
    ],
    price: "5000 р."
  },
  {
    title: "групповые занятия для взрослых",
    info_text: [
      "От 4-7 человек в группе",
      "Минимум 8 занятий",
      "Длительность занятия 2 аккадемических часа",
      "Преодоление языкового и слухового барьера"
    ],
    price: "11500 р."
  },
  {
    title: "подготовка к экзаменам",
    info_text: [
      "ОГЭ, ЕГЭ, TOEFL, IELTS",
      "Колличество занятий вы выбираете самостоятельно ",
      "Длительность занятия 1 аккадемический час",
      "Программа, цели обучения и индивидуальные задачи, а также цены обсуждаются на консультации"
    ],
    price: "Уточнить цену"
  }


];

const PricesBlock = () => {

  const [swiper, setSwiper] = useState(null);

  return (
    <div id="prices" className="prices_block item_container">
    <div className="prices">
      <div className="prices__title">
        <span>цены</span>
        <center><p>Вы можете ознакомиться с нашими ценами и 
          выбрать наиболее подходящий для вас вариант</p></center>
      </div>
      <div className="prices__container">
        <div style={{
                // position: "relative",
                paddingTop: "5.2vh",
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: 'center',
        }}>
          <SlidePrevButton swiper={swiper}/>
          <Swiper
          modules={[Navigation, A11y]}
          breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 85,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 85,
            },
            660: {
              slidesPerView: 2,
              spaceBetween: 45,
            },

            890: {
              slidesPerView: 2,
              spaceBetween: 85,
            },
            1221: {
              slidesPerView: 2,
              spaceBetween: 105,
            },
            1222: {
              slidesPerView: 3,
              spaceBetween: 55,
            },
            2000: {
              slidesPerView: 3,
              spaceBetween: 105,
            }         
          }}
          // centeredSlides={true}
          // slidesPerView={3}
          // spaceBetween={55}
          // centeredSlides={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={setSwiper}
          className="mySwiper"
          // navigation
        >
      
          {PriceData.map((data, index_data) => (
            <SwiperSlide key={index_data} className='mySwiperSlide'>
              <div className="card">
                <div className="card__title">
                  <center><span>{data.title}</span></center>
                </div>
                <div className="card__info">
                  {
                    data.info_text.map((info, index_info) => (
                      <center key={index_info}><p key={index_info}>{info}</p></center>
                    ))
                  }
                </div>
                <center><div className="card__price">{data.price}</div></center>
                <button>
                  <a href="#form" onClick={(e) => handleNavigate(e)}>
                    <span>записаться</span>
                  </a>
                </button>
              </div>
            </SwiperSlide>
          ))}
          </Swiper>
          <SlideNextButton swiper={swiper}/>
        </div>
      </div>

    </div>

  </div>
  );

}


export default PricesBlock;