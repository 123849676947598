import {useEffect, useRef, useState} from 'react';
import '../styles/train_program.css'
import planet from '../img/programs_block/planet.svg'
import decorate_planet from '../img/programs_block/decorate_planet.svg'
import cloud from '../img/about_block/Cloud.svg'
import half_cloud from '../img/programs_block/half_cloud.svg'
import plane from '../img/programs_block/plane.svg'
import planet_center from '../img/programs_block/planet_center.svg'
import planet_main from '../img/programs_block/planet_main.svg'
import balloon from '../img/programs_block/air_balloon.svg'
import arrow from '../img/arrows/left_arrow_white.svg'
import { handleNavigate } from './header/Header'
import planet_trees from '../img/programs_block/trees.svg'
import planet_axis from '../img/programs_block/axis.svg'


const ProgramData = [
  {
    title: "ИНДИВИДУАЛЬНЫЕ ЗАНЯТИЯ",
    hour: "1 академический час",
    count_lessons: "минимум 4 занятия",
    count_people: "",
    introduction: "Добро пожаловать на индивидуальные занятия по английскому для детей и взрослых",
    enum: [
      "Персональный и гибкий подход к обучению",
      "Развитие словарного запаса под руководством опытных преподавателей",
      "Максимально персонализированный опыт изучения английского языка"
    ]

  },
  {
    title: "ГРУППОВЫЕ ЗАНЯТИЯ",
    hour: "1 час",
    count_lessons: "минимум 8 занятий",
    count_people: "от 4-7 человек",
    introduction: "Добро пожаловать на групповые занятия по английскому языку для детей и взрослых",
    enum: [
      "Уникальная атмосфера сотрудничества и вдохновения на занятиях",
      "Развитие навыков общения под руководством опытных преподавателей",
      "Создание уютной языковой общины, где обучение превращается в приключение",
      "Присоединяйтесь к исследованию мира языка в веселой и поддерживающей обстановке групповых занятий"
    ]

  },
  {
    title: "ПОДГОТОВКА К ЭКЗАМЕНАМ",
    hour: "1 академический час",
    count_lessons: "",
    count_people: "",
    introduction: "Добро пожаловать на курсы подготовки к экзаменам",
    enum: [
      "Программы для подготовки к ОГЭ, ЕГЭ, TOEFL, IELTS",
      "Структурированный и интенсивный подход для успешной сдачи",
      "Усовершенствование языковых и стратегических навыков",
      "Создание пути к успешному будущему, преодоление каждого шага экзаменационного пути",
      "Подготовка к экзаменам с уверенность и эффективностью"  
    ]

  },
  {
    title: "ИНТЕНСИВЫ",
    hour: "",
    count_lessons: "",
    count_people: "",
    introduction: "Добро пожаловать на интенсивные курсы английского для детей и взрослых ",
    enum: [
      "Захватывающий опыт обучения с интерактивными уроками и приключениями",
      "Погружение в английский с квалифицированными преподавателями",
      "Усовершенствование языковых и стратегических навыков",
      "Создание основ владения языком и ярких воспоминаний",
      "Увлекательное совместное путешествие в мир языка и открытий"
    ]

  },
  {
    title: "Разговорный клуб\nкиноклуб",
    hour: "2 академических часа",
    count_lessons: "",
    count_people: "от 5-7 человек",
    introduction: "Добро пожаловать в уютные разговорные клубы английского",
    enum: [
      "Изучение языка становится частью увлекательных диалогов",
      "Встречи для обсуждения разнообразных тем с носителями языка",
      "Увлекательные сеансы в киноклубе для обогащения словарного запаса",
      "Атмосфера, где общение и кино становятся ключами к английскому языку"
    ]

  },
]

const degrees = [0, 39, 42.5, 28.5, 206];
const TrainProgramsBlock = () => {

  const [activePlanet, setActivePlanet] = useState(false);
  const [program, setProgram] = useState(0);
  const [rotation, setRotation] = useState(null);
  const [intrBlockIsVisible, setIntrBlockIsVisible] = useState();
  const intrBlockRef = useRef();


  const toggleProgram = () => {
    if(program < ProgramData.length - 1) {
      setProgram(program + 1);
      if(rotation === null)
        setRotation(degrees[program+1]);
      else 
        setRotation(rotation + degrees[program+1]);
    }
      
    else {
      setProgram(0);
      setRotation(degrees[0]);
    }
  }

  useEffect( () => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIntrBlockIsVisible(entry.isIntersecting)
    })

    observer.observe(intrBlockRef.current);
  }, [])


  return (
    <div id="programs" className='programs_block'>
    {/* <div className='item_container'> */}
    <img src={cloud} alt="" className='cloud cloud__1'></img>
    <img src={cloud} alt="" className='cloud cloud__2'></img>
    {/* <img src={plane} alt="" className='plane'></img> */}
    <img src={cloud} alt="" className='small_cloud small_cloud__1'></img>
    <img src={cloud} alt="" className='small_cloud small_cloud__2'></img>


    {/* <img src={planet_main} 
        alt=""
        className={`planet ${activePlanet ? "active" : ""}`} 
        style={ rotation !== null ? 
          { 
          transform: `rotate(${rotation}deg)`,
          transition: 'transform 2s',
          }
          :
          {}
        }
    ></img> */}
    <div className={`planet_box ${activePlanet ? "active" : ""}`}>
      <img src={decorate_planet} 
        alt=""
        className="planet" 
        style={ rotation !== null ? 
          { 
          transform: `rotate(${rotation}deg)`,
          transition: 'transform 2s',
          }
          :
          {}
        }>
      </img>
      <img src={planet_axis} alt="" className={`axis_img ${activePlanet ? "hidden" : ""}`}></img>
    </div>


    <img src={cloud} alt="" className='cloud cloud__3'></img>
    <img src={half_cloud} alt="" className='cloud cloud__4'></img>
    <img src={cloud} alt="" className='small_cloud small_cloud__3'></img>
    <img src={cloud} alt="" className='small_cloud small_cloud__4'></img>

    <div className={`changing_block ${intrBlockIsVisible ? "animated" : ""} ${activePlanet ? "bigger" : ""} ${program > 0 ? "auto_height" : ""}`}
          onClick={() => setActivePlanet(true)}
    >
      <div ref={intrBlockRef} className={`introductory_block  ${activePlanet ? "hide_block" : ""}`}>
        <div className='title'>программы обучения</div>
        <div className='text'>Выберите вариант обучения, который подходит именно Вам!</div>
      </div>

      <div className={`programm_block ${activePlanet ? "show_block" : ""}`}>
        <div className='title_block'>
          <span className='title'>{ProgramData[program].title}</span>
        </div>
        <div className='info_block'>
          <div className='first_div'>
            <div>
              <span className={
                `${ProgramData[program].hour &&
                  ProgramData[program].count_lessons 
                  || ProgramData[program].count_people ? "bottom_right" : ""}`
                }>{ProgramData[program].hour}</span>
              <span className={
                `${ProgramData[program].count_lessons && 
                  ProgramData[program].count_people ? "bottom_right" : ""}
                ${ProgramData[program].count_lessons && 
                  ProgramData[program].hour ? "padding_left" : ""}`  
                }>{ProgramData[program].count_lessons}</span>
              <span className={
                    `${ProgramData[program].hour ||
                      ProgramData[program].count_lessons ? "padding_left" : ""}`
                   }>{ProgramData[program].count_people}</span>
            </div>
            <button onClick={() => toggleProgram()}>
              <img src={arrow} alt="" className=''></img>
            </button>
          </div>
          <div>
            <span>{ProgramData[program].introduction}</span>
            <ul>
              {
                ProgramData[program].enum.map((item, index) => {
                   return(
                    <li key={index}>{item}</li>
                   ) 
                })
              }
            </ul>
          </div>
          <div>
            <button className='more_btn'>
              <a href="#prices" onClick={(e) => handleNavigate(e)}>
                Подробнее
              </a>
            </button>
            <button className="toggle_btn" onClick={() => toggleProgram()}>
              <img src={arrow} alt="" className=''></img>
            </button>
          </div>
        </div>

      </div>
      
    </div>
  </div>
  );
}


export default TrainProgramsBlock;