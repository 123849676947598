import {useEffect, useState} from 'react';
import '../styles/form.css'
import FormInput from './FormInput';
import emailjs from '@emailjs/browser';
import DotLoader from 'react-spinners/DotLoader'
import check_mark from '../img/form_block/check_mark.svg'
import cross from '../img/form_block/cross.svg'

const FormBlock = () => {

  const [formValues, setFormValues] = useState({
    userName: "",
    phoneNumber: "",
    email: "",
    city: "",
    consent: false
  })

  const [formValid, setFormValid] = useState({
    userName: true,
    phoneNumber: true,
    email: true,
    city: true
  })

  const [isLockedBtn, setIsLockedBtn] = useState(true);
  
  const [loading, setLoading] = useState(false);
  const [showMassage, setShowMassage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail]       = useState(false);

  const inputs = [
    {
      id: 1,
      name: "userName",
      type: "text",
      placeholder: "Ваше имя",
      regular: null
    },
    {
      id: 2,
      name: "phoneNumber",
      type: "text",
      placeholder: "",
      regular: /^\d{10}$/
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: "Электронная почта",
      regular: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    },
    {
      id: 4,
      name: "city",
      type: "text",
      placeholder: "Город",
      regular: null
    }
  ]; 

  const sendEmail = (e) => {
    e.preventDefault();
    
    const serviceID = 'service_5x929zc';
    const templateID = 'template_441srak';
    const publicKey = 'jxZOUcfJgKy36RFW0';

    const sendData = {
      userName: formValues.userName,
      phoneNumber: formValues.phoneNumber,
      email: formValues.email,
      city: formValues.city
    }

    if(!isLockedBtn) {
      setLoading(true)

      // setTimeout(() => {
      //   setLoading(false)
      //   setShowMassage(true)
      //   setTimeout(() => {
      //     setSuccess(true)
      //     setTimeout(() => {
      //       setShowMassage(false)
      //       setSuccess(false)

      //     setFormValues({
      //       userName: "",
      //       phoneNumber: "",
      //       email: "",
      //       city: "",
      //       consent: false
      //     });


      //     }, 3000)
      //   }, 100) 
      // }, 1000)
      
    emailjs.send(serviceID, templateID, sendData, publicKey)
      .then(
        (response) => {
          setLoading(false)
          setShowMassage(true)

          setTimeout(() => {
            setSuccess(true)
              setTimeout(() => {
                setShowMassage(false)
                setSuccess(false)
      
                setFormValues({
                  userName: "",
                  phoneNumber: "",
                  email: "",
                  city: "",
                  consent: false
                });
                }, 2000)
              }, 100) 
        })
        .catch((error) => {
          setLoading(false)
          setShowMassage(true)

          setTimeout(() => {
            setFail(true)
              setTimeout(() => {
                setShowMassage(false)
                setFail(false)
                }, 2000)
              }, 100) 
        });
    }
  };

  const onChange = (id, name, value) => {
    setFormValues({
      ...formValues, 
        [name]: value
      })

      if(id)
        valid(id);
  }

  const valid = (id) => {
    if(inputs[id-1].regular) {
      setFormValid({
        ...formValid,
        [inputs[id-1].name]: inputs[id-1].regular.test(formValues[inputs[id-1].name])
      })
    } else {
      setFormValid({
        ...formValid,
        [inputs[id-1].name]: true
      })
    }
    
  }

  useEffect(() => {
    setIsLockedBtn(
      formValues.userName && 
      formValues.phoneNumber && 
      formValues.email &&
      formValues.consent &&
      formValid.userName && 
      formValid.phoneNumber && 
      formValid.email ? false : true
   );

  }, [formValues, formValid]);

  return (
    <div id="form" className='form_block item_container'>
      <div className='white_block'>
        <div className='form'>
          <span className='title'>запишись на урок</span>
          <div className='inputs_block'>
            <form onSubmit={sendEmail}>
              {inputs.map((input) => {
                return(
                  <FormInput 
                    key={input.id}
                    {...input}
                    value={formValues[input.name]}
                    formValid={formValid}
                    onChange={onChange}

                  />
                );
              })}

            <div className='consent'>
              <input 
                type="checkbox" 
                name="consent" 
                checked={formValues.consent} 
                onChange={() => onChange(null, "consent", !formValues.consent)}  />
              <label>
                Даю свое согласие на обработку персональных данных в 
                соответствии с пользовательским соглашением
              </label>
            </div>

            <div type="submit" className="submit_btn">
              {loading ? 
                <DotLoader color="#E03635" size="5vh"/>
                :
                <button className={`button 
                ${showMassage ? "response_massage" : "submit"} 
                ${success ? "active_massage success" : fail ? "active_massage fail" : ""}
                ${isLockedBtn && !showMassage ? "locked" : "unlocked"}`}>
                  <i class="fa-solid fa-check"></i>
                  {
                    success ? 
                    <img src={check_mark} style={{width: "3.8vh", height: "3.8vh"}} className="" alt=""></img>
                    :
                    fail ?
                    <img src={cross} style={{width: "3.8vh", height: "3.8vh"}} className="" alt=""></img>
                    :
                    "записаться на пробный урок"
                  }
                </button>
              }
            </div>
            </form>
          </div>


        </div>
        <div className='decorate_block'></div>

        
      </div>

    </div>
  );
}

export default FormBlock;