import '../styles/questions.css'
import {useState} from 'react';

const QuestionsBlock = () => {

  const [isOpen, setIsOpen] = useState(null);

  const Questions = [
    {
      question: "Сколько стоит и длится занятие?",
      answer: "Стоимость и продолжительность занятия зависит от программы, которую вы выбираете. Индивидуальные занятия с детьми длятся 45 минут и стоят 1625₽ за занятие. Индивидуальные занятия с взрослыми стоят 1250₽ за 45 минут. Групповые занятия для детей длятся 60 минут, 625₽/занятие. Взрослые групповые занятия длятся 90 минут, 1440₽/занятие. Другие формы проведения занятий обговариваются в индивидуальном порядке."
    },
    {
      question: "Какого результата можно добиться в вашей школе за месяц?",
      answer: "При качественном выполнении всех рекомендаций и заданий преподавателя уже спустя месяц вы обнаружите прогресс в ваших языковых навыках: взрослые смогут говорить на любую тему, связанную с туризмом, а дети научатся отвечать на вопросы. Спустя 2-3 месяца вы сможете заметить, что языковой барьер действительно исчезает."
    },
    {
      question: "Кому подходит школа?",
      answer: "Наши занятия подстраиваются индивидуально под каждого ученика, поэтому учитываются все детали: возраст, интересы, цели, слабые места. Наши профессиональные преподаватели подберут идеальную программу под любого ученика."
    },
    {
      question: "Какие формы обучения есть в вашей школе?",
      answer: "Школа предоставляет большой спектр различных форматов изучения языка, чтобы вы точно нашли самый удобный и интересный для себя:",
      list: [
        "групповые и индивидуальные занятия",
        "интенсивы",
        "разговорный клуб",
        "курс подготовки к экзамену (ОГЭ, ЕГЭ, TOEFL, IELTS)."
      ]
    },
    {
      question: "Как построено обучение в школе?",
      answer: "Перед началом занятий мы всегда проводим консультацию, где определяем, какие у вас цели на изучение языка. После этого подбираем идеального для вас преподавателя, который разрабатывает индивидуальную программу и настраивает вектор дальнейшей работы. Каждый наш специалист прошёл профессиональную подготовку, поэтому использует самые эффективные методы преподавания и работает на основе книг от Cambridge, Oxford и Pearson."
    },
    {
      question: "Проходят ли занятия во время праздничных дней?",
      answer: "Только на программе «интенсив». На ней занятия проводятся только во время выходных дней."
    },

  ]

  const toggleState = (index) => {
    if(isOpen === index)
      return setIsOpen(null)

      setIsOpen(index)

  }

  return(
    <div className='questions_block'>
      <div className='container'>
        <div className='container__title'>
          <span>самые популярные вопросы</span>
        </div>
        <div className='accordion'>
          {Questions.map((item, index) => {
            return (
              <div key={index} 
                  className="item"
                  onClick={() => toggleState(index)}>
                <div className='title'>
                  <span className={`question ${isOpen === index ? "" : "border_bottom"} ${index === 0 ? "first" : ""}`}>{item.question}</span>
                  <div className={`plus ${isOpen === index ? "active" : ""}`}>
                    <span></span>
                  </div>
                </div>
                <div className={isOpen === index ? "content show" : "content"}>
                  <div className={isOpen !== index ? "show_top_border" : "hide_top_border"}>{item.answer}</div>
                  <div></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default QuestionsBlock;