import '../styles/quote_block.css'
import cloud from "../img/about_block/Cloud.svg"
import plain from "../img/quote_block/plain.svg"


const QuoteBlock = () => (
<div className='quote_block'> 
{/* <div className='item_container'> */}
  <div className='quote_text'>
    <div className='cloud_up_left'>
      <img src={cloud} className="cloud_up_left__img" alt=""></img>
    </div>
    <div className='cloud_up_right'>
      <img src={cloud} className="cloud_up_right__img" alt=""></img>
    </div>
    <div className='plain'>
      <img src={plain} className="plain__img" alt=""></img>
    </div>
    <span>"не будьте рабом английского, сделайте английский своим рабом"</span>
    <div className='cloud_down'>
      <img src={cloud} className="cloud_down__img" alt=""></img>
    </div>

  </div>





</div>
)

export default QuoteBlock;