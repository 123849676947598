import {useState} from 'react';

const HamburgerButton = ({isActive, toggleBurgerButCallback}) => {

  return (
    <div className={`burger_btn ${isActive ? "active" : ""}`}
    onClick={() => toggleBurgerButCallback()}  
    >
      <span></span>
    </div>
  );

}


export default HamburgerButton;