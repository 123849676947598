import '../styles/footer.css'
import telegram from '../img/contact_icons/telegram.svg'
import whatsapp from '../img/contact_icons/whatsapp.svg'
import vk from '../img/contact_icons/vk.svg'
import instagram from '../img/contact_icons/instagram.svg'
import logo_btn from '../img/welcome_block/logo.svg'
import { handleNavigate } from './header/Header'

const Footer = () => {

  return(
    <div id="footer" className='footer_block item_container__last'>
      <div className='contacts_block'>
        <div className='contacts'>
          <span>Контакты:</span>
          <span itemprop="telephone">+7 (964)-812-85-85</span>
          <span>ИНН 784234394157</span>
          <span itemprop="email">englishwithelina0@gmail.com</span>
          <div className='icons_group'>
            <a href="#">
              <img src={telegram} className="icon telegram" alt=""></img>
            </a>
             <a href="#">
              <img src={whatsapp} className="icon whatsapp" alt=""></img>
             </a>
             <a href="https://vk.com/englishwithelina">
              <img src={vk} className="icon vk" alt=""></img>
             </a>
             <a href="https://www.instagram.com/englishschool_elina?igsh=bTdkdWtjOWs2d3M5">
              <img src={instagram} className="icon instagram" alt=""></img>
             </a>
            
          </div>
        </div>
        {/* <div className='company_info'>
          <span>ИП Микаелян Элина Завеновна</span>
          <span>ОГРНИП 324385000010793</span>
          <span>ИНН 784234394157</span>
        </div> */}
        <div className='logo_btn'>
          <a href="#main" onClick={(e) => handleNavigate(e)}>
            <img src={logo_btn} className="logo" alt=""></img>
            </a>
          <span>Школа английского языка</span>
        </div>
      </div>

    </div>
  );
}

export default Footer;