
const NavMenu = ({isOpen, toggleMenuActive}) => {

  const menuHandleNavigate = (event) => {
    event.preventDefault();
    const blockID = event.currentTarget.getAttribute('href');
    const element = document.querySelector(blockID);
  
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    toggleMenuActive(false)
  }

  return (
    <nav className={`nav_menu ${isOpen ? "open" : "close"}`}>
      <ul>
        <li><a href="#main" onClick={(e) => menuHandleNavigate(e)}>ГЛАВНАЯ</a></li>
        <li><a href="#about" onClick={(e) => menuHandleNavigate(e)}>О НАС</a></li>
        <li><a href="#programs" onClick={(e) => menuHandleNavigate(e)}>ПРОГРАММЫ</a></li>
        <li><a href="#prices" onClick={(e) => menuHandleNavigate(e)}>ЦЕНЫ</a></li>
        <li><a href="#feedbacks" onClick={(e) => menuHandleNavigate(e)}>ОТЗЫВЫ</a></li>
        <li><a href="#footer" onClick={(e) => menuHandleNavigate(e)}>ОБРАТНАЯ СВЯЗЬ</a></li>
        <div>
          <button><a href="#form" onClick={(e) => menuHandleNavigate(e)}>ОСТАВИТЬ ЗАЯВКУ</a></button>
        </div>
      </ul>
    </nav>
  );
}





export default NavMenu;