import "../styles/about.css"
import teacher from "../img/about_block/teacher.svg"
import elina from "../img/about_block/elina.svg"
import cloud from "../img/about_block/Cloud.svg"
import big_plane from "../img/about_block/Plane.svg"
import { useEffect, useRef, useState } from "react"

const AboutBlock = () => {

  const plainRef = useRef();
  const [plainIsVisible, setPlainIsVisible] = useState();

  useEffect( () => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setPlainIsVisible(entry.isIntersecting)
    })

    observer.observe(plainRef.current);
  }, [])

  // console.log("plainIsVisible", plainIsVisible)

  return ( 
  <div id="about" className="about_block item_container">
  {/* <div className="item_container"> */}
  <div className="about_text">
    <div className="title">О нас</div>
    <div className="description">
      <span>Hey, welcome to «English with Elina» school!</span>
      <div className="description__text">
        <p>Я Элина, а это моя школа английского языка с лучшей командой
        преподавателей, где каждый проходит специальную подготовку
        перед началом работы с учениками.</p>
        <p>У нас вы можете полностью подстроить занятия под себя.</p>
        <p>Вы выбираете НАС, а наша команда подготавливает лучшую 
        программу и подход для ВАС!</p>
      </div>
      </div>
  </div>
    
  <div className="teacher_clouds">
      {/* <img src={elina} className="teacher__img" alt=""></img> */}
    <div className="cloud_left">
      <img src={cloud} className="cloud_left__img" alt=""></img>
      <div className="left_content text">
        <p>Игровые</p>
        <p>и коммуникативные</p>
        <p>методики</p>
      </div>
    </div>
    <div className="cloud_right">
      <img src={cloud} className="cloud_right__img" alt=""></img>
      <div className="right_content text">
        <p>Используем</p>
        <p>лучшие пособия от</p>
        {/* <p>Cambridge, Oxford и Pearson</p> */}
        <p>CAMBRIDGE, OXFORD и PEARSON</p>
      </div>
    </div>
  </div>

  {/* <div className="plain_block"> */}
  <div className={`plain_block ${plainIsVisible ? "visible" : ""}`}>
    <img ref={plainRef} src={big_plane} className="plain__img" alt=""></img>
  </div>

</div>);
 
}
  
  export default AboutBlock;