import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const FormInput = (props) => {

  const {onChange, id, formValid, ...inputProps} = props;
  // console.log("props")
  // console.log(props)
  return (
    <>
    { props.id === 2 ? 
      <PhoneInput 
      
        className = {`input_phone ${formValid[props.name] ? "" : "border_error_phone"}`}
        country={'ru'}
        {...inputProps}
        onChange={(value) => onChange(props.id, props.name, value)}
        inputProps={{
          required: true,
        }}
      
      />
      :
      <input className={`input ${!formValid[props.name] && "border_error"}`}
      {...inputProps}
      onChange={(e) => onChange(props.id, props.name, e.target.value)}
      />

    }
    </>


  );
}

export default FormInput;