import AboutBlock from "./components/AboutBlock";
import CertificatesBlock from "./components/CertificatesBlock";
import FeedbackBlock from "./components/FeedbackBlock";
import Footer from "./components/Footer";
import FormBlock from "./components/FormBlock";
import Header from "./components/header/Header";
import PricesBlock from "./components/PricesBlock";
import QuestionsBlock from "./components/QuestionsBlock";
import QuoteBlock from "./components/QuoteBlock";
import TrainProgramsBlock from "./components/TrainProgramsBlock";
import "./styles/app_block.css"
import "../src/js/scroll.js"

function App() {
  return (
    <>
      <div itemscope itemtype="http://schema.org/Organization" className="main_container">
        <Header/>
        <AboutBlock/>
        <TrainProgramsBlock/>
        <QuoteBlock/>
        <PricesBlock/>
        <FeedbackBlock/>
        <CertificatesBlock/>
        <FormBlock/>
        <QuestionsBlock/>
        <Footer/>
       </div>
      
    </>
    

  );
}

export default App;
