import leftArrow from "../../img/arrows/left_arrow.svg"
import rightArrow from "../../img/arrows/right_arrov.svg"
import "../../styles/buttons.css"
// import { useSwiper } from 'swiper/react';

export default function SlideNextButton({ swiper }) {
  
  return (
    <button 
      className="swiper_button" 
      onClick={() => swiper && swiper.slideNext()}
    >
      <img src={ rightArrow } className="arrow_img" alt=""></img>
    </button>
  );
}

export function SlidePrevButton({ swiper }) {

  return (
    <button 
      className="swiper_button" 
      onClick={() => swiper && swiper.slidePrev()}
    >
      <img src={ leftArrow } className="arrow_img" alt=""></img>
    </button>
  );
}


// const SwiperButton = ({ direction, swiper }) => {

//   console.log("direction = " + direction)
//    return (
//     <button 
//       className="swiper_button" 
//       onClick={() => swiper && swiper.slideNext()}
//     >
//       <img src={
//         direction === "left" ? 
//         leftArrow 
//         : direction === "right" 
//           ? rightArrow : ""
//       } 
//         alt="" 
//         className="arrow_img"></img>
//     </button>
//    );
// };

// export default SlideNextButton;