import {useState} from 'react';
import "../styles/feedbacks.css"
import India from "../img/feedback_block/slides_back/india.svg";
import London from "../img/feedback_block/slides_back/london.svg";
import screensaver_0 from '../img/feedback_block/screensavers/screensaver_1.svg'
import screensaver_1 from '../img/feedback_block/screensavers/screensaver_2.svg'
import PlayButton from '../img/buttons/play_button.svg'

const VideoFeedback = ({data}) => { 

  const [isPlay, setIsPlay] = useState(false);

  return (
    <div className="video">
    {
      !isPlay ? 
        <>
          
          <img src={data.source && data.source} className="screensaver_img" alt=""></img>
          <img src={ PlayButton }    
              className="play_btn" 
              alt=""
              onClick={() => setIsPlay(true)}
              // onClick={() => togglePlay(true)}
              >
          </img>
        </>
        :
        <iframe 
          src={data.feedback} 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowfullscreen
        >
        </iframe>
       
    }

  </div>
  );
}

export default VideoFeedback;